@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Kdam+Thmor+Pro&family=Raleway&display=swap');
:root {
	--color1: #001aff;
	--color2: #85bf62;
	--color3: purple;
	--color4: #ffc400;
	--color5: rgb(253, 95, 95);
  }

* {
	box-sizing: border-box;
	scrollbar-width: none;
	/*-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;*/
}

html, body {
	/* width: 100vw;
	height: 100vh; */
	/* overflow: hidden; */
	height: 100%;
	margin: 0;
	font-family: 'Raleway', sans-serif;
}

form {
	width: 100%;
}

#root {
	overflow: hidden;  /* because mobile browsers ignore body tag */
	width: 100vw;
	height: 100%;
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%; */
	/* height: 100vh; */
	
}

hr {
	margin-top: 1.8rem;
	width: 100%;
  }

.main-container {
	position: fixed;
	top: 25vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	width: 100%;
}

.full-height {
	min-height:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: env(safe-area-inset-bottom);
}

a {
	text-decoration: none;
}

h1 {
	padding: 0;
	margin: 0;
}

.center {
	display: flex;
	/* flex-direction: column;
	height: 100%;
	justify-content: center;
	align-content: center;
	align-items: center; */
}
	
	

.container {
	border-radius: 50%;
	position: relative;
	top: 15vh;
}
	

.circle {
	width: 100px;
	height: 100px;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, -50%);
	content: "";
	background-color: var(--color1);
	border-radius: 50%;
	/*z-index: 10;*/
}

#cat2 {
	transform : rotate(72deg);
	transform-origin : bottom center;
	position : absolute;
	height : 100px;
	left : 50%;
}

#cat2 .circle {
	background-color: var(--color2);
}

#cat3 {
	transform : rotate(144deg);
	transform-origin : bottom center;
	position : absolute;
	height : 100px;
	left : 50%;
}

#cat3 .circle {
	background-color: var(--color3);
}

#cat4 {
	transform : rotate(216deg);
	transform-origin : bottom center;
	position : absolute;
	height : 100px;
	left : 50%;
	
}

#cat4 .circle {
	background-color: var(--color4);
}

#cat5 {
	transform : rotate(288deg);
	transform-origin : bottom center;
	position : absolute;
	height : 100px;
	left : 50%;
	
}

#cat5 .circle {
	background-color: var(--color5);
}

.expand {
	height: 300vw;
	width: 300vw;
	transition: 1s ease;
	z-index: 100;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
}

.text {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 80%;
	justify-content: space-between;
	position: relative;
	z-index: 100;
	color:white;
	font-size: 2rem;
	text-align: center;
}

.question {
	margin: 2rem 0 0 0;
}

.task {
	height: 100%;
	width: auto;
	z-index: 100;
	color:white;
	font-size: 2rem;
	text-align: center;
	padding: 0; /*padding-top*/
}

.fadein {
	animation: fade-in 0.5s ease-in;
}

@keyframes fade-in {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.fadeout {
	animation: fade-out 1s ease-in;
	background-color: black;
}

@keyframes fade-out {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	justify-items: center;
	align-items: center;
	width: 100%;
}

.button {
	min-width: 40vw;
	margin: 0.5rem 0 0 0;
	background-color: white;
	color: rgb(160, 160, 160);
	cursor: pointer;
	padding: 9px 18px;
	border-radius: 9px;
}

.settings_button {
	background-color: blueviolet;
	color: white;
	margin: 10% 0 10% 0;
	width: 30%;
	font-size: 2rem;
	
}

.cat1 {
	color: var(--color1);
}

.cat2 {
	color: var(--color2);
}

.cat3 {
	color: var(--color3);
}

.cat4 {
	color: var(--color4);
}

.cat5 {
	color: var(--color5);
}

.bg1 {
	background-color: var(--color1);
}

.bg2 {
	background-color: var(--color2);
}

.bg3 {
	background-color: var(--color3);
}

.bg4 {
	background-color: var(--color4);
}

.bg5 {
	background-color: var(--color5);
}

.correct {
	background-color: greenyellow;
	color: white;
}

.corrected {
	animation: shakeX 1s linear;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	
}


@keyframes shakeX {
	from,
	to {
	  transform: translate3d(0, 0, 0);
	}
  
	10%,
	30%,
	50%,
	70%,
	90% {
	  transform: translate3d(-10px, 0, 0);
	}
  
	20%,
	40%,
	60%,
	80% {
	  transform: translate3d(10px, 0, 0);
	}
}

.incorrect {
	background-color: red;
	color: white;
}

header {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: white;
}

.logos {
	display: flex;
	flex-direction: row;
	padding: 0 0 0 .5rem;
	margin: 0;
	z-index: 200;
	height: 80px;
}

.menu {
	position: relative;
	right: 0.2rem;
	z-index: 200;
	font-size: 2rem;
	padding: .4em;
	cursor: pointer;
}

.menu-content {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
	font-size: 2rem;
	z-index: 199;
}

.menu_button {
	position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
	padding: .425em 0; /*must be less than half*/
	background-color: currentColor;
	background-clip: content-box;
	transition: background-color 1s;
	color: rgb(194, 194, 194);
	/*font-size: 40px;*/
}
.menu_button::before,
.menu_button::after {
	content: '';
	position: absolute;
	width: 100%;
	height: .15em;
	background-color: currentColor;
	transition: top .5s .5s, transform .5s;
}

.menu_button::before {
	top: .05em;
}
.menu_button::after {
	top: .8em;
}
  
.menu_button_opened {
	background-color: transparent;
}
.menu_button_opened::before,
.menu_button_opened::after {
	top: .4em;
	transition: top .5s, transform .5s .5s;
}
.menu_button_opened::before{
	transform: rotate(-45deg) scaleX(1.2);
}
.menu_button_opened::after {
	transform: rotate(45deg) scaleX(1.2);
}

.form-group {
  /* margin: 0 auto 1.25rem auto; */
  padding: 0.25rem;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.5rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin: 0 0 0.5rem 0;
}

input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.settings {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 0;
	padding: 80px 2rem 0 2rem;
	/*padding-bottom: 0;
	padding-top: 80px;*/

}

.settings h1 {
	width: 100%;
}

.multiplayer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

/* .multiplayer h2 {

} */

.player {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: 90%;
}

.players-grid {
	display: grid;
	grid-template-columns: 2fr 2fr 1fr 1fr;
	/* Create four columns of equal width */
	gap: 8px;
	/* Adjust the gap between grid items as necessary */
}

.players-grid div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.input-radio,
.input-checkbox {
  display: inline-block;
  margin: 0 0.625rem 0 0;
  min-height: 1.25rem;
  min-width: 1.25rem;
}

.game-text {
	width: 100%;
	font-size: 2.1rem;
	text-align: center;
	margin: 60vh 0 0 0;
}

.hidden {
	opacity: 0;
}

.show {
	opacity: 1;
	transition: 1s ease;
	transition-delay: 1s;
}

.timer {
	display: flex;
	flex-direction: column;
	opacity: 0.5;
}

.counter {
	font-size: 14rem;
	line-height: 14rem;
	font-family: 'Anton', sans-serif;
}


@keyframes bounceIn {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
  
	0% {
	  opacity: 0;
	  transform: scale3d(0.3, 0.3, 0.3);
	}
  
	20% {
	  transform: scale3d(1.1, 1.1, 1.1);
	}
  
	40% {
	  transform: scale3d(0.9, 0.9, 0.9);
	}
  
	60% {
	  opacity: 1;
	  transform: scale3d(1.03, 1.03, 1.03);
	}
  
	80% {
	  transform: scale3d(0.97, 0.97, 0.97);
	}
  
	to {
	  opacity: 1;
	  transform: scale3d(1, 1, 1);
	}
}
  
.bounceIn {
	animation-duration: 2s;
	animation-name: bounceIn;
	
}

@keyframes pulse {
	from {
	  transform: scale3d(1, 1, 1);
	}
  
	50% {
	  transform: scale3d(1.05, 1.05, 1.05);
	}
  
	to {
	  transform: scale3d(1, 1, 1);
	}
}
  
.pulse {
	animation-name: pulse;
	animation-duration: 0.5s;
	animation-delay: 1s;
	animation-timing-function: ease-in-out;
}

@keyframes rollIn {
	from {
	  
	  transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}
  
	to {	  
	  transform: translate3d(0, 0, 0);
	}
}
  
.rollIn {
	animation-name: rollIn;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
}

@keyframes rotateInDownRight {
	from {
	  transform: rotate3d(0, 0, 1, 45deg); 
	}

	to {
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .rotateInDownRight {
	animation-name: rotateInDownRight;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	transform-origin: right bottom;
  }

  @keyframes slideInDown {
	from {
	  transform: translate3d(0, -100%, 0);
	  visibility: visible;
	}
  
	to {
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .slideInDown {
	animation-name: slideInDown;
	animation-duration: 0.5s;
  }

.info {
	width: 100%;
	margin: 80px auto 2rem auto; /*auto*/
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 2rem 2rem 2rem;
}

.info img {
	max-height: 200px;
	max-width: 100%;
	margin: 0 auto;
}

.info h1, .info h2 {
	width: 100%;
	text-align: left;
}

.info-text {
	font-size: 1.5rem;
}

table {
	margin: 1rem 0;
	border-collapse: collapse;
	border-spacing: 0;
	width: 70%;
	border: 1px solid rgb(153, 151, 151);
}

tr {
	border-bottom: 1px solid rgb(153, 151, 151);
}

table td, th {
	padding: 5px;
}
  
thead {
	text-align: left;
	font-weight: bold;
}

ul {
	margin: 0;
}

ul li {
	margin: .7rem 0;
}

.small_circle {
	width: 20px;
	height: 20px;
	content: "";
	
	border-radius: 50%;
	z-index: 10;
}

.multiplayer_container {
	display: flex;
	align-items: center;
}

.multiplayer_circle {
	background-color: blueviolet;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	position: relative; /* Added for positioning context */
	margin: 0 .3rem;
}

.multiplayer_number {
	color: white;
	font-size: 1.8rem;
	font-weight: bold;
	text-align: center;
	z-index: 10;
	font-family: 'Open sans';
	position: absolute; /* For absolute positioning inside the circle */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /* Center the number */
}

.multiplayer_icons {
	display: flex;
	height: 100%;
	flex-direction: column;
	gap: .3rem;
}

.button-icon {
	color: blueviolet;
	font-size: 1.3rem;
	cursor: pointer;
}

.bg_color_1 {
	background-color: var(--color1);
}

.bg_color_2 {
	background-color: var(--color2);
}
.bg_color_3 {
	background-color: var(--color3);
}
.bg_color_4 {
	background-color: var(--color4);
}
.bg_color_5 {
	background-color: var(--color5);
}

.vjs-tech {
	/*position: fixed !important;*/
	filter: opacity(50%)
}

.video-js {
	background-color: transparent !important;
	position: absolute !important;
	height: 100% !important;
}

.video-js.vjs-default-skin .vjs-big-play-button .vjs-paused { 
	display: none !important;
}

.vjs-loading-spinner {
	display: none !important;
  }

.loader {
	display: flex;
	position: absolute;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
}

.signature-container {
	position: absolute;
	bottom: 0;
	font-size: 1rem;
	font-family: 'Courier New', Courier, monospace;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.signature-container img {
	/*margin: 0 auto;*/
	height: 60px;
}

.signature-container:hover {
	opacity: 1;
}

/* ex dice.css */

.title {
  font: 16px/20px Arial, sans-serif;
  color: #999;
  text-align: center;
}

.dice-container {
  display: flex;
  justify-content: center;
}

.dice {
  width: 100px;
  height: 100px;
  margin: 50px;
  perspective: 400px;
}

.cube {
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  transform: translateZ(-50px) rotateX(0) rotateY(0);
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.side {
  position: absolute;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 2px;
  overflow: hidden;
  background: purple;
  backface-visibility: hidden;
}

  .side_1 {
    transform: translateZ(-50px) rotateX(180deg);
  }
  
  .side_2 {
    transform: translateY(-50px) rotateX(90deg);
  }
  
  .side_3 {
    transform: translateX(50px) rotateY(90deg);
  }

  .side_4 {
    transform: translateX(-50px) rotateY(270deg);
  }

  .side_5 {
    transform: translateY(50px) rotateX(270deg);
  }

  .side_6 {
    transform: translateZ(50px);
  }

.dice[data-val="1"] .cube {
  transform: translateZ(-50px) rotateX(180deg) rotateY(0deg);
}

.dice[data-val="2"] .cube {
  transform: translateZ(-50px) rotateX(-90deg) rotateY(0deg);
}

.dice[data-val="3"] .cube {
  transform: translateZ(-50px) rotateX(0deg) rotateY(-90deg);
}

.dice[data-val="4"] .cube {
  transform: translateZ(-50px) rotateX(0deg) rotateY(90deg);
}

.dice[data-val="5"] .cube {
  transform: translateZ(-50px) rotateX(90deg) rotateY(0deg);
}

.dice[data-val="0"] .cube {
  transition: transform 1s cubic-bezier(0.8, -100, 0.1, 1); /*0.55, 0.055, 0.675, 0.19    .5, -500, .2, 100   0,667,0.5,667*/
}

.dice_1[data-val="0"] .cube {
  transform: translateZ(-50px) rotateX(359deg) rotateY(359deg);
}
.dice_2[data-val="0"] .cube {
  transform: translateZ(-50px) rotateX(-359deg) rotateY(-359deg);
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0 0 10px rgba(204, 0, 0, 0.5);
}
  
  .dot_1, .dot_2, .dot_3 {
    top: 20px;
  }
  .dot_4, .dot_5, .dot_6 {
    top: 50px;
  }
  .dot_7, .dot_8, .dot_9 {
    top: 80px;
  }
  .dot_1, .dot_4, .dot_7 {
    left: 20px;
  }
  .dot_2, .dot_5, .dot_8 {
    left: 50px;
  }
  .dot_3, .dot_6, .dot_9 {
    left: 80px;
  }

#result {
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dice-button {
  background-color: teal;
  color:white;
  margin: 3rem 0 0 0;
}

@media (orientation: portrait) {

	.video-js {
		width: 100%;
		height: 100%;
	}
	
	video {
		object-fit: cover;
	}

	.question {
		margin: 2rem .75rem 0 .75rem;
	}
}

@media (min-width: 576px) and (orientation: portrait) {
	.settings,
	.info {
	  max-width: 540px;
	}

}



@media (max-width: 576px) {
	.button {
		width: 90vw;
	}
	
	
}
  
@media (min-width: 768px) {
	.text,
	.settings,
	.info {
	  max-width: 720px;
	}

}

@media (max-width: 768px)  and (orientation: portrait) {
	.info-text {
		font-size: 1rem;
		margin: 0 .75rem 0 .75rem
	}
	/* .text {
		font-size: 1.5rem;
	} */

	.exTitle {
		font-size: 1.8rem;
		margin: 0 .75rem 0 .75rem
	}
}

@media (min-width: 1200px) {
	.button {
		width: 100%;
		min-width: 10vw;
		max-width: 20vw;
	}

	.exTitle {
		width: 30vw;
	}
}

@media (max-height: 500px) {
	.text {
		margin: 40px 0 0 0;
	}

	.dice-button {
		margin: 2rem 0 0 0;
	}

	.game-text {
		font-size: 1.8rem;
		margin: 70vh .75rem 0 .75rem;
	}

	.button {
		margin: 1.5rem 0 0 0;
	}
	
}

@media (max-width: 320px) {
	#result {
		font-size: 1.7rem;
	}
	
	.info-text {
		font-size: 1rem;
		margin: 0 .75rem 0 .75rem
	}
	.text {
		font-size: 1.5rem;
	}

	.exTitle {
		font-size: 1.5rem;
	}

	.counter {
		font-size: 12rem;
	}

	.game-text {
		width: auto;
	}

}

.info-icon {
	color: #001aff;
	font-size: 1.1rem;
}

.info-icon-text {
	position: relative;
	left: .5rem
}